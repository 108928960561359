import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Which React Hook Should I Use?`}</h1>
    <p>{`I want to:`}</p>
    <ul>
      <li parentName="ul"><Link to="data" mdxType="Link">Persist data across re-renders</Link></li>
      <li parentName="ul"><Link to="function" mdxType="Link">Create a function to be used within a component</Link></li>
      <li parentName="ul"><Link to="hooks/useContext" mdxType="Link">
  Provide data and functions to different parts of a component hierarchy
        </Link></li>
      <li parentName="ul"><Link to="hooks/useEffect" mdxType="Link">Perform an action due to data change</Link></li>
      <li parentName="ul"><Link to="hooks/useEffect" mdxType="Link">
  Perform an action when a component renders/re-renders
        </Link></li>
      <li parentName="ul"><Link to="hooks/useEffect" mdxType="Link">
  Perform an action when a component mounts/unmounts
        </Link></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html"
      }}>{`Hooks documentation`}</a></p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      